import * as React from "react"

import { Navbar } from "./Navbar"
import { Footer } from "./Footer"
import CookieConsent from "react-cookie-consent"
// import { useScrollRestoration } from "gatsby"

const Layout = ({ children, navbar, footer, activeDocMeta }) => {
  // const scrollRestoration = useScrollRestoration(`layout-scroll`)

  return (
    <>
      <Navbar navbar={navbar} activeDocMeta={activeDocMeta} />
      <main className="main-st"
      // {...scrollRestoration}
      >
        {children}
      </main>
      <Footer footer={footer} />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
      >
        <h4>This websites uses cookies.</h4>
        <p>We use cookies to make the site work better, but also to see how you interact with it. how you interact with it. We will only use cookies if you allow us to do so by clicking by clicking on "Accept Cookies". You can also choose which cookie you want to allow.</p>
      </CookieConsent>
    </>
  )
}

export default Layout
